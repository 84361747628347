.store-logos {
  .title {
    color: darkgray;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: "Karnak-Pro Book";
  }

  .links {
    display: flex;
    justify-content: space-evenly;
    img {
      height: 2.5rem;
      cursor: pointer;

      @media screen and (max-width: 950px) {
        width: 60%;
        margin-bottom: 5px;
      }

      @media screen and (max-width: 1200px) {
        height: 2.25rem;
      }
    }

    @media screen and (max-width: 950px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
