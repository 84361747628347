.more-games {
  font-size: 14px;
  font-family: "Karnak-Pro Book";
  margin-bottom: 1.5rem !important;

  .title {
    color: darkgray;
    margin-bottom: 5px;
  }

  .game-list {
    display: flex;
    align-items: center;
    cursor: pointer;

    .game-image {
      height: 14px;
      width: 14px;
      margin-right: 5px;
    }

    .game-title {
      color: white;
    }
  }
}
