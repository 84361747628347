.header {
  background-color: black;
  width: 100%;
  display: flex;
  padding: 5px 0 5px 5px;
  color: white;
  box-sizing: border-box;
  align-items: center;

  > * {
    display: flex;
    align-items: center;
  }

  .items-left {
    .menu-icon {
      font-size: 1.5rem;
      cursor: pointer;
    }

    .title {
      font-size: 18px;
      margin-top: 2px;
    }

    > * {
      margin-right: 10px;
    }
  }
  .items-right {
    margin-left: auto;
    .logo {
      height: 25px;
      width: 40px;
    }

    > * {
      margin-left: 10px;
    }
  }
}
