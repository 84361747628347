.dots-4 {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(
      circle closest-side at left 6px top 50%,
      currentColor 90%,
      #0000
    ),
    radial-gradient(circle closest-side, currentColor 90%, #0000),
    radial-gradient(
      circle closest-side at right 6px top 50%,
      currentColor 90%,
      #0000
    );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  animation: d4 300ms infinite alternate;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
@keyframes d4 {
  to {
    width: 20px;
    aspect-ratio: 1;
  }
}
