.side-bar {
  background-color: #454545;
  z-index: 2;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  overflow: scroll;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.3s;
  width: 25%;

  > * {
    padding: 0 10px;
    margin-bottom: 5px;
  }

  .side-bar-header {
    display: flex;
    align-items: center;
    background-color: #252525;
    color: white;
    height: 3rem;
    .title {
      font-size: 20px;
    }
    .side-bar-close {
      margin-left: auto;
      cursor: pointer;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1100px) {
    width: 30%;
  }

  @media screen and (max-width: 950px) {
    width: 35%;
  }

  @media screen and (max-width: 750px) {
    width: 45%;
  }

  @media screen and (max-width: 550px) {
    width: 55%;
  }

  @media screen and (max-width: 450px) {
    width: 65%;
  }
}
