@font-face {
  font-family: "Karnak-Pro Light";
  src: url("./assests/fonts/KarnakPro-Light.otf") format("opentype");
}
@font-face {
  font-family: "Karnak-Pro Book";
  src: url("./assests/fonts/KarnakPro-Book.otf") format("opentype");
}

html {
  height: 100vh;
}

body {
  font-family: "Karnak-Pro Light";
  height: 100%;
  margin: 0;
  background-color: #1a3352;
}
