.game-frame {
  width: 100%;
  position: absolute;
  top: 35px;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;

  .game-box {
    width: 70%;
    height: 100%;
    box-shadow: 0 0 10px rgb(0 0 0);
    position: relative;

    iframe {
      width: 100%;
      height: inherit;
      border: 0;
    }

    .bottom-ad {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100px;
    }

    @media screen and (max-width: 1024px) {
      width: 60%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      box-shadow: none;
    }
  }
}
