.links {
  font-family: "Karnak-Pro Book";
  .title {
    color: darkgray;
    font-size: 14px;
    margin-bottom: 5px;
  }

  a {
    display: flex;
    color: white;
    font-size: 14px;
    margin-bottom: 5px;
    text-decoration: none;
    align-items: flex-start;

    img {
      margin-right: 8px;
      width: 14px;
      height: 14px;
    }
  }
}
