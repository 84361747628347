@keyframes example {
  from {
    top: 70%;
  }
  to {
    top: 50%;
  }
}

.prompt-popup {
  width: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 3;
  background-color: white;
  border-radius: 5px;
  animation-name: example;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  color: black;
  padding: 10px 5px 2px 5px;
  font-family: "Karnak-Pro Book";
  font-size: 16px;

  .title {
    margin-bottom: 10px;
    padding-bottom: 5px;
    text-align: center;
  }
  .app-details {
    margin: 16px 0;
    justify-content: center;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
    margin-bottom: 10px;

    .daily-word-logo {
      height: 70px;
      width: 70px;
    }
    .add-logo {
      height: 20px;
      width: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .prompt-buttons {
    display: flex;
    flex-direction: column;

    .add-button {
      background-color: #006aff;
      color: white;
      width: 100%;
      height: 2rem;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 5px;
    }
    .skip-button {
      width: 100%;
      font-size: 10px;
      color: gray;
      margin-top: 2px;
      cursor: pointer;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 30%;
  }
  @media screen and (max-width: 1024px) {
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 60%;
  }
  @media screen and (max-width: 370px) {
    width: 80%;
  }
}

.install-icon {
  font-size: 1.4rem;
  cursor: pointer;
}
